import React, { useState, useRef, useEffect } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import { Title, SubTitle, Button } from "../../../StyleComponents/styles"
import {
  BoxColorido,
  ContainerMatriz,
} from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Generator", link: "/generator/" },
  {
    name: "Color Palette Generator",
    link: "/color-palette-generator/",
  },
]
const seeAlsoArray = ["/color-converter"]

function ColorPaletteGenerator(props) {
  // code here
  let boxColor1 = useRef("")
  let boxColor2 = useRef("")
  let boxColor3 = useRef("")
  let boxColor4 = useRef("")
  let boxColor5 = useRef("")
  let color1 = useRef("")
  let color2 = useRef("")
  let color3 = useRef("")
  let color4 = useRef("")
  let color5 = useRef("")
  let [NameColor1, setNameColor1] = useState("")
  let [NameColor2, setNameColor2] = useState("")
  let [NameColor3, setNameColor3] = useState("")
  let [NameColor4, setNameColor4] = useState("")
  let [NameColor5, setNameColor5] = useState("")

  let [NameboxColor1, setNameboxColor1] = useState("")
  let [NameboxColor2, setNameboxColor2] = useState("")
  let [NameboxColor3, setNameboxColor3] = useState("")
  let [NameboxColor4, setNameboxColor4] = useState("")
  let [NameboxColor5, setNameboxColor5] = useState("")

  function generate() {
    generateColor(boxColor1, setNameboxColor1, setNameColor1, color1)
    generateColor(boxColor2, setNameboxColor2, setNameColor2, color2)
    generateColor(boxColor3, setNameboxColor3, setNameColor3, color3)
    generateColor(boxColor4, setNameboxColor4, setNameColor4, color4)
    generateColor(boxColor5, setNameboxColor5, setNameColor5, color5)
  }

  useEffect(() => {
    generate()
  }, [])

  function generateColor(e, ne, n, b) {
    let caracteres_HEX = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ]
    function embaralha_array(array) {
      array.sort(function () {
        return Math.random() * 2048 - Math.random() * 2048
      })
    }

    embaralha_array(caracteres_HEX.sort())
    var posicoes_caracteres_HEX = ["0", "1", "2", "3", "4", "5"]
    embaralha_array(posicoes_caracteres_HEX.sort())
    var cor_HEXADECIMAL =
      "#" +
      caracteres_HEX[0] +
      caracteres_HEX[1] +
      caracteres_HEX[2] +
      caracteres_HEX[3] +
      caracteres_HEX[4] +
      caracteres_HEX[5]

    e.current.style.backgroundColor = cor_HEXADECIMAL
    ne(cor_HEXADECIMAL)
    n(cor_HEXADECIMAL)
    b.current.style.color = cor_HEXADECIMAL
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Random Color Palette Generator"
        description="Generate a random color palette, press on the button, and generate 5 colors palette online. wee help you find the perfect color palette."
        keywords={[
          "random color,generate color,Color Palette generator, Color Palette generator, random Color Palette generator, random Color Palette,make Color Palette, color generator, palette of color generator, random Color Palette, random colors hex, random hex color palette,color palette picker,pick Color Palette.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Color Palette Generator</Title>
        <SubTitle>
          Random{" "}
          <span role="img" aria-label="img">
            🎨
          </span>{" "}
          Color Palette Generator
        </SubTitle>
        <Button
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          className="mb-4"
          onClick={generate}
        >
          Make Random Color Palette
        </Button>
        <br />
        <ContainerMatriz>
          <div className="rOw">
            <ul className="listUnstyled">
              <li>
                <BoxColorido ref={boxColor1}>
                  <p>{NameboxColor1}</p>
                </BoxColorido>
              </li>
              <li>
                <BoxColorido ref={boxColor2}>
                  <p>{NameboxColor2}</p>
                </BoxColorido>
              </li>
              <li>
                <BoxColorido ref={boxColor3}>
                  <p>{NameboxColor3}</p>
                </BoxColorido>
              </li>
              <li>
                <BoxColorido ref={boxColor4}>
                  <p>{NameboxColor4}</p>
                </BoxColorido>
              </li>
              <li>
                <BoxColorido ref={boxColor5}>
                  <p>{NameboxColor5}</p>
                </BoxColorido>
              </li>
            </ul>
          </div>
        </ContainerMatriz>
        <div>
          <strong ref={color1}>{NameColor1}</strong>
        </div>
        <div>
          <strong ref={color2}>{NameColor2}</strong>
        </div>
        <div>
          <strong ref={color3}>{NameColor3}</strong>
        </div>
        <div>
          <strong ref={color4}>{NameColor4}</strong>
        </div>
        <div>
          <strong ref={color5}>{NameColor5}</strong>
        </div>
        <br />
        <h4>Random Color Palette Generator</h4>
        <p>
          Generate a random color palette, simply press on the button, and
          generate a 5 color. We help you find the perfect color palette.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ColorPaletteGenerator
